<template>
  <v-container>
    <v-row class="justify-center">
      <v-col v-if="hasAdminRole" class="d-flex justify-end align-end" cols="12" md="12"><v-btn to="/badges/groups">View Groups</v-btn></v-col>
      <v-col cols="12" md="12" xl="12">
        <v-card outlined>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <h2>What are badges?</h2>
                  <span>Badges are awardable items that demonstrate that a developer completed training, worked on a certain project, or participated in a specific event. They're not only a visual demonstration of a job-well-done but they're also a credential that gives developers access into systems and additional functions at HCA.</span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="11" md="8">
                  <v-text-field
                    label="Search for badges by name, description, or tag"
                    placeholder=""
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    clearable
                    v-model="search"
                    :loading="isLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="badges.length > 0">
      <v-col cols="12" md="4" v-for="(item, index) in badges" :key="index">
         <v-card
            @click="routeToBadge(item.name)"
            :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
            class="ma-2 pa-1"
            outlined
            hover
            ripple
          >
            <v-img
              contain
              max-height="150"
              style="height: 150px"
              :transition="false"
              :src="item.imageBase64"
              :lazy-src="badgeLoadingImage"
              class="ma-2 pt-2"
            ></v-img>

            <v-card-subtitle class="d-flex justify-center">{{ item.displayName }}</v-card-subtitle>
            <v-card-text>
              <v-chip class="ma-1" small v-for="(chip, chipIndex) in item.tags" :key="'chip' + chipIndex">
                {{ chip }}
              </v-chip>
            </v-card-text>
         </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <span>No badges were found</span>
      </v-col>
    </v-row>
    <v-row v-if="count > 1">
      <v-col>
        <v-pagination
          v-model="page"
          :length="count"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'BadgeList',
  watch: {
    search: function () {
      clearTimeout(this.searchTimeout)

      // Make a new timeout set to go off in 800ms
      this.searchTimeout = setTimeout(() => {
        if (this.search == null) {
          this.search = ''
        }
        this.page = 1
        this.getBadges()
      }, 500)
    },
    page: function () {
      this.getBadges()
    }
  },
  computed: {
    ...mapGetters([
      'oidcUser',
      'badgeUser'
    ]),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined
      }
    }
  },
  data: () => ({
    isDeleting: false,
    badgeLoadingImage: require('@/assets/defaultBadge.svg'),
    badges: [ ],
    isLoading: true,
    page: 1,
    limit: 12,
    count: 0,
    search: '',
    searchTimeout: null
  }),
  methods: {
    routeToBadge: function (badgeName) {
      this.$router.push(`/badges/details/${badgeName}`)
    },
    getBadges: function () {
      this.isLoading = true
      axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges?page=${this.page}&limit=${this.limit}&search=${this.search}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.badges = response.data.items
            this.count = Math.ceil(response.data.totalCount / this.limit)

            this.badges.forEach(x => {
              this.getImage(x)
            })
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getBadges() })
          })
    },
    getImage: function (badge) {
      if (badge.image) {
        return axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/images/${badge.image.id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              badge.imageBase64 = response.data.base64Image
              this.$forceUpdate()
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
            })
      }
    }
  },
  mounted: function () {
    this.getBadges()
    this.$store.commit('breadcrumbs', [
      {
        text: 'Badges',
        exact: true,
        disabled: false,
        to: '/badges'
      }
    ])
  }
}
</script>

<style scoped>
.v-window__container {
    height: 100% !important;
  }
</style>
